import React, { useEffect, useState } from "react";
import { Box, Grid, Container, Typography, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BackHand } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  myComponent: {
    position: "absolute",
    right: "0px",
    width: "100%",
    background: "#1E2835",
    borderRadius: "10px",
    "& h6": {
      color: "#FFF",
      textAlign: "center",
      padding: "10px 0px",
      fontSize: "16px",
      fontWeight: "400",
    },
    "& .buttonBox": {
      position: "absolute",
      left: "20px",
      // top: "10px",
      "& button": {
        background: "transparent",
        height: "0px",
        width: "0px",
      },
    },
    "& .walletBox": {
      background: "#233042",
      borderRadius: "10px",
      border: "1px solid #C9801F",
      padding: "10px",
      margin: "20px",
      flexDirection: "column",
      "& span": {
        color: "rgba(255, 255, 255, 0.60)",
        fontSize: "12px",
        marginTop: "2px",
      },
      "& h6": {
        padding: "0px",
        marginTop: "1px !important",
      },
    },
  },
});
export default function ConnectWalletScreen() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box style={{ position: "relative", height: "94dvh" }}>
      <Container>
        <Box className={classes.myComponent}>
          <Box
            style={{
              position: "relative",
            }}
          >
            <Box className="buttonBox">
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Typography variant="h6">Disconnect Wallet</Typography>
          </Box>
          <Box
            className="walletBox displayCenter"
            onClick={() => navigate("/link-wallet", { state: location.state })}
          >
            <img
              src="images/walletImg.png"
              alt=""
              style={{ width: "50px", height: "50px" }}
            />
            <Typography variant="h6">Disconnect Wallet</Typography>

            <img
              style={{ cursor: "pointer", marginTop: "10px" }}
              src="images/button.svg"
              alt=""
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
