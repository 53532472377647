import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  IconButton,
  Button,
  Avatar,
  Stack,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import { useAccount, useConnect, useEnsName } from "wagmi";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
import { InjectedConnector } from "wagmi/connectors/injected";
import ButtonCircular from "./ButtonCircular";
import useStoreToken from "./../storeToken";

const useStyles = makeStyles({
  myComponent: {
    position: "relative",
    background: "#1E2835",
    borderRadius: "20px",
    padding: "35px 0px  ",
    "& h6": {
      color: "#FFF",
    },
    "& .buttonBox": {
      position: "absolute",
      left: "20px",
      top: "10px",
      "& button": {
        background: "transparent",
        height: "0px",
        width: "0px",
      },
    },
  },
});
export default function TokenList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  // const [tokenList, setTokenList] = useState([]);

  const { items, addItem } = useStoreToken();

  const [tokenListNew, setTokenListNew] = useState([]);
  console.log(" ----- items ", items);
  const tokenTaxDedectorHistory = async (address) => {
    try {
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.tokenTaxDedector,
        bodyData: {
          walletAddress: address ? address : "",
        },
      });
      if (res.data.responseCode === 200) {
        const holdings = res?.data?.responseResult?.Holdings;
        const tokenAddresses = res?.data?.responseResult?.TokenAddresses;

        if (!holdings || !tokenAddresses) {
          console.error("Invalid response format");
          return;
        }

        const verifiedHoldings = [];
        const verifiedHoldings1 = [];

        for (const tokenAddress of tokenAddresses) {
          const verificationResponse = await isSpanData(tokenAddress);
          // Check if the verification was successful and honeypot is false
          if (
            verificationResponse.tokenAddress &&
            verificationResponse.honeyPot === false
          ) {
            // Find the matching holding in the holdings array
            if (
              verificationResponse.selltax > 0 ||
              verificationResponse.buytax > 0
            ) {
              const matchingHolding = holdings.find(
                (holding) =>
                  holding.token_address === verificationResponse.tokenAddress
              );
              const matchingHolding1 = holdings.find(
                (holding) =>
                  holding.token_address === verificationResponse.tokenAddress
              );

              if (matchingHolding) {
                verifiedHoldings.push({
                  ...matchingHolding,
                  verificationStatus: verificationResponse,
                });

                // setTokenList((prevTokenList) => {
                //   const updatedTokenList = [...prevTokenList, matchingHolding1];
                //   const uniqueTokenList = Array.from(
                //     new Set(updatedTokenList.map((item) => item.token_address))
                //   ).map((tokenAddress) =>
                //     updatedTokenList.find(
                //       (item) => item.token_address === tokenAddress
                //     )
                //   );

                //   return uniqueTokenList;
                // });

                const updatedTokenList = [matchingHolding1];
                const uniqueTokenList = Array.from(
                  new Set(updatedTokenList.map((item) => item.token_address))
                ).map((tokenAddress) =>
                  updatedTokenList.find(
                    (item) => item.token_address === tokenAddress
                  )
                );
                verifiedHoldings1.push(updatedTokenList[0]);
                console.log("Verified hh:", ...verifiedHoldings);
              }
            }
          }
        }
        setIsLoading(false);
        addItem(verifiedHoldings1);
        // At this point, verifiedHoldings contains the filtered holdings

        // You can set the result to your state or use it as needed
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error processing holdings:", error);
    }
  };

  const isSpanData = async (address) => {
    try {
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.isSpam,
        bodyData: {
          // walletAddress: "0x1a15AA321c6dF985Ff631F338Bf31597c40827D2",
          tokenAddress: address,
        },
      });

      if (res.data.responseCode === 200) {
        return {
          tokenAddress: res.data.responseResult.tokenAddress,
          honeyPot: res.data.responseResult.honeyPot,
          buytax: Number(res.data.responseResult.buytax),
          selltax: Number(res.data.responseResult.selltax),
        };
      } else {
        return {
          tokenAddress: "",
          honeyPot: false,
          buytax: 0,
          selltax: 0,
        };
      }
    } catch (error) {
      console.error("Error checking isSpanData:", error);
      return {
        tokenAddress: "",
        honeyPot: false,
        buytax: 0,
        selltax: 0,
      };
    }
  };
  // console.log("Verified Holdings:", tokenListNew);
  const listTransacyionHistory = async (address) => {
    try {
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.getAllTransaction,
        bodyData: {
          // walletAddress: "0x1a15AA321c6dF985Ff631F338Bf31597c40827D2",
          walletAddress: address
            ? address
            : "0x1a15AA321c6dF985Ff631F338Bf31597c40827D2",
        },
      });

      if (res.data.data) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const addres =
      location &&
      location.state &&
      location.state.profileData &&
      location.state.profileData.walletAddress &&
      location.state.profileData.walletAddress;
    if (addres && items.length == 0) {
      setIsLoading(true);
    }
    if (addres) {
      tokenTaxDedectorHistory(addres);
    }
  }, [location]);
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name) {
    console.log("name-=-=-", name.split(""));
    return {
      sx: {
        bgcolor: stringToColor(name),
      },

      children: `${name.split("")[0]}`,
    };
  }

  return (
    <Box style={{ position: "relative", height: "94dvh" }}>
      <Box className={classes.myComponent}>
        <Container>
          <Box className="homeCardBox displayStart">
            <IconButton onClick={() => navigate(-1)} className=" walletButton">
              <ArrowBackIcon />
            </IconButton>
            <Box ml={3}>
              <Typography variant="h5" color="#fff">
                Tokens List
              </Typography>
            </Box>
          </Box>

          {isLoading ? (
            <ButtonCircular />
          ) : (
            <>
              {items &&
                items?.map((item, index) => {
                  return (
                    <Box
                      className="homeCardBox displayStart"
                      mt={1}
                      onClick={() =>
                        navigate("/wallet-transaction", { state: item })
                      }
                    >
                      <Stack>
                        {item && item?.logo === null && (
                          <Avatar
                            style={{ height: "25px", width: "25px" }}
                            {...stringAvatar(item && item?.symbol)}
                          />
                        )}

                        {item && item?.logo && (
                          <Avatar
                            style={{ height: "25px", width: "25px" }}
                            src={item?.logo}
                          />
                        )}
                      </Stack>
                      <Box ml={1}>
                        <Typography
                          style={{ textAlign: "center" }}
                          variant="h6"
                        >
                          {item && item?.symbol}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              {items && items?.length === 0 && (
                <Box mt={2}>
                  <Typography textAlign="center" variant="h6">
                    Data Not found
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Container>
      </Box>
    </Box>
  );
}
