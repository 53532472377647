import React, { useEffect, useState } from "react";

import axios from "axios";

import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import ApiConfig from "src/config/APICongig";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { InjectedConnector } from "wagmi/connectors/injected";
import { useNavigate } from "react-router-dom";

export default function CustomConnectBtn() {
  const navigate = useNavigate();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  // const { activate, account } = useWeb3React();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  let chatId = window.location.search.split("?")[1];
  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    if (address) {
      window.localStorage.setItem("account", address);
      const channelUsername = "RepoToken_bot";
      const telegramUrl = `tg://resolve?domain=${channelUsername}`;
      window.location.href = telegramUrl;

      //   updateWallet(address);
    }
  }, [address]);

  return (
    <Container maxWidth="md">
      <Box style={{ marginTop: "154px", marginBottom: "64px" }}>
        <Paper
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "35px",
            flexDirection: "column",
            minHeight: "300px",
            backgroundColor: "#0000002b",
          }}
        >
          <Box style={{ padding: "20px" }}>
            <img src="/images/centerImage.png" alt="centerImg" width="100%" />
          </Box>
          <Typography
            style={{
              color: "#fff",
              marginBottom: "28px",
              fontSize: "30px",
              fontWeight: "400px",
            }}
            variant="h4"
          >
            Connect your wallet
          </Typography>

          <ConnectButton />
        </Paper>
      </Box>
    </Container>
  );
}
