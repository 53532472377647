import CommonCard from "src/component/CommonCard";
import ConnectWalletScreen from "src/component/ConnectWalletScreen";
import React from "react";
import ConnectWalletLink from "src/component/ConnectWalletLink";
import ConnectWalletSuccessFully from "src/component/ConnectWalletSuccessFully";
import NotificationComponent from "src/component/NotificationComponent";
import RevenueShare from "src/component/RevenueShare";
import AskQuestion from "src/component/AskQuestion";
import TokenList from "../component/TokenList";
import CustomConnectBtn from "src/component/CustomConnectBtn";
const Tokeninfo = React.lazy(() => import("../pages/Tokeninfo"));
const HomePage = React.lazy(() => import("src/pages/Home"));
const BuySection = React.lazy(() => import("../component/buyModal/BuyModal"));
const Repo = React.lazy(() => import("src/pages/Repo"));
const Wallettransaction = React.lazy(() =>
  import("../pages/Wallettransaction")
);

const routes = [
  {
    path: "/",
    element: HomePage,
  },

  {
    path: "/wallet-transaction",
    element: Wallettransaction,
  },
  {
    path: "/repo",
    element: Repo,
  },
  {
    path: "/token-info",
    element: Tokeninfo,
  },
  {
    path: "/wallet",
    element: CommonCard,
  },
  {
    path: "/connect",
    element: ConnectWalletScreen,
  },
  {
    path: "/link-wallet",
    element: ConnectWalletLink,
  },
  {
    path: "/success",
    element: ConnectWalletSuccessFully,
  },
  {
    path: "/notification",
    element: NotificationComponent,
  },
  {
    path: "/revenue-share",
    element: RevenueShare,
  },
  {
    path: "/ask-question",
    element: AskQuestion,
  },
  {
    path: "/list-token",
    element: TokenList,
  },
  {
    path: "/buy-token",
    element: BuySection,
  },
  {
    path: "/customConnectBtn",
    element: CustomConnectBtn,
  },

  // {
  //   path: "/404",
  //   element: PageNotFound,
  //
  // },
];
export default routes;
