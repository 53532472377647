export const themeOptions = {
  typography: {
    h1: {
      fontFamily: "'Roboto Condensed', sans-serif",
      fontWeight: 600,
      fontSize: 50,
      letterSpacing: "1px",
      "@media (max-width:1024px)": {
        fontSize: "40px !important",
      },
      "@media(max-width:767px)": {
        fontSize: "30px !important",
      },
    },
    h2: {
      fontFamily: "'Roboto Condensed', sans-serif",
      letterSpacing: "1px",
      fontWeight: 600,
      fontSize: 40,
      wordBreak: "break-word",
      "@media(max-width:767px)": {
        fontSize: "25px !important",
      },
    },
    h3: {
      fontFamily: "'Roboto Condensed', sans-serif",
      fontWeight: 400,
      fontSize: 35,
      color: "#161E29",

      "@media(max-width:767px)": {
        fontSize: "23px !important",
      },
    },
    h4: {
      fontFamily: "'Roboto Condensed', sans-serif",
      fontWeight: 400,
      fontSize: 30,
      "@media(max-width:767px)": {
        fontSize: "20px !important",
      },
    },
    h5: {
      fontFamily: "'Roboto Condensed', sans-serif",
      fontWeight: 400,
      fontSize: 15,
      "@media(max-width:767px)": {
        fontSize: "15px !important",
      },
    },
    h6: {
      fontFamily: "'Roboto Condensed', sans-serif",
      fontWeight: 300,
      fontSize: 16,
      "@media(max-width:767px)": {
        fontSize: "14px !important",
      },
    },
    body2: {
      fontFamily: "'Roboto Condensed', sans-serif",
      fontSize: 14,
      fontWeight: 300,
      "@media(max-width:767px)": {
        fontSize: "12px",
      },
    },
    body1: {
      fontFamily: "'Roboto Condensed', sans-serif",
      fontWeight: 300,
      fontSize: 12,
    },
    subTitle1: {
      fontFamily: "'Roboto Condensed', sans-serif",
      fontWeight: 400,
      fontSize: 14,
    },
  },
};
