import axios from "axios";

export const apiRouterCall = async ({
  method,
  url,
  bodyData,
  paramsData,
  token,
}) => {
  try {
    return await axios({
      method: method,
      url: url,
      // headers: {
      //   token: token ? token : window.sessionStorage.getItem("token"),
      // },
      data: bodyData ? bodyData : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error?.response?.data);
    return error?.response;
  }
};
