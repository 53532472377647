import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BackHand, ContentCopy } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { sortAddress } from "src/utils";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const useStyles = makeStyles({
  myComponent: {
    position: "absolute",
    right: "0px",
    width: "100%",
    background: "#1E2835",
    borderRadius: "10px",
    "& h5": {
      color: "#FFF",
      textAlign: "center",
      fontWeight: "400",
      marginTop: "10px",
    },

    "& .walletBox": {
      margin: "20px",
      flexDirection: "column",
      "& span": {
        fontSize: "14px",
        margin: "4px 0px 15px",
        color: "rgba(255, 255, 255, 0.60)",
        maxWidth: "250px",
        textAlign: "center",
      },
    },
  },
});
export default function ConnectWalletSuccessFully() {
  const classes = useStyles();
  const location = useLocation();

  console.log("location=-=-=-=", location);

  const { address, isConnected } = useAccount();
  const navigate = useNavigate();

  return (
    <Box style={{ position: "relative", height: "94dvh" }}>
      <Container>
        <Box className={classes.myComponent}>
          <Box className="walletBox displayCenter">
            <img src="images/successConnect.png" alt="" />
            <Typography variant="h5">Wallet Connected Successfully!</Typography>
            <span>Your wallet has been successfully connected.</span>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => navigate("/", { state: location.state.chatIdURL })}
            >
              Return to Main Menu
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
