import React from "react";
import "@rainbow-me/rainbowkit/styles.css";
import {
  RainbowKitProvider,
  darkTheme,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";

import { RouterProvider } from "react-router-dom";
import router from "./router";

// import { Toaster } from "react-hot-toast";
import { mainnet } from "wagmi/chains";
import {
  injectedWallet,
  metaMaskWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";

const projectId = "48db81f9c07b27a43aac8646ef71e116";

function App() {
  const toasterOptions = {
    style: {
      fontSize: "16px", // Adjust the font size here
      wordBreak: "break-word",
      // fontFamily: "Arial",
    },
  };

  //rainbowkit and wagmi connectors implementation for establishing web3 connectivity
  const { chains, publicClient } = configureChains(
    [mainnet],
    [publicProvider()]
  );
  const connectors = connectorsForWallets([
    // ...wallets,
    {
      groupName: "sniffer_bot",
      wallets: [
        injectedWallet({ projectId, chains }),
        metaMaskWallet({ projectId, chains }),
        // trustWallet({ projectId, chains }),
        walletConnectWallet({ projectId, chains }),
      ],
    },
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  return (
    <div className="bg-primary w-full overflow-hidden">
      {" "}
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          chains={chains}
          coolMode
          modalSize="compact"
          theme={darkTheme({
            accentColor: "#D63A3A",
            accentColorForeground: "#fff",
            borderRadius: "large",
            fontStack: "system",
            overlayBlur: "small",
            fontWeight: "500px",
            whiteSpace: "pre",
            height: "46px",
          })}
        >
          <RouterProvider router={router} />
        </RainbowKitProvider>
      </WagmiConfig>
    </div>
  );
}

export default App;
