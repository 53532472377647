import React from "react";
import { Box, Container, Typography, IconButton, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  myComponent: {
    position: "absolute",
    right: "0px",
    width: "100%",
    background: "#1E2835",
    borderRadius: "10px",
    "& h6": {
      color: "#FFF",
      textAlign: "center",
      padding: "10px 0px",
      fontSize: "16px",
      fontWeight: "400",
    },
    "& .buttonBox": {
      position: "absolute",
      left: "20px",
      // top: "10px",
      "& button": {
        background: "transparent",
        height: "0px",
        width: "0px",
      },
    },
    "& .walletBox": {
      borderRadius: "10px",
      border: "1px solid #374D69",
      padding: "10px",
      margin: "5px 20px 20px",
      flexDirection: "column",
      "& h6": {
        padding: "0px",
      },
      "& .decriptionBox": {
        color: "rgba(255, 255, 255, 0.60)",
        textAlign: "center",
        fontSize: "14px !important",
      },
    },
  },
});
export default function RevenueShare() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box style={{ position: "relative", height: "94dvh" }}>
      <Container>
        <Box className={classes.myComponent}>
          <Box
            style={{
              position: "relative",
            }}
          >
            <Box className="buttonBox">
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Typography variant="h6">Coming Soon!</Typography>
          </Box>
          <Box className="walletBox displayCenter">
            <img src="images/commingSoon.png" />
            <Typography variant="h6">
              REPO BOT will soon automatically calculate your repossession.
            </Typography>
            <Typography variant="body2" className="decriptionBox" mb={2} mt={2}>
              Interested in receiving an automated repossession? Just buy and
              hold $REPO.
            </Typography>
            <Typography variant="body2" className="decriptionBox">
              Repos are allocated monthly based on your token ownership. Hold
              for a month, and you'll receive a share of the community
              repossession fund. If you own 1% of the total supply, your repo
              will represent 1% of the distributed fund. Participate in our
              straight forward revenue-sharing model—no staking required!
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
