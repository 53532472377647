const url = "https://node-abdelwebapp.mobiloitte.io/api/v1";
// const url = "http://172.16.2.11:2033/api/v1";

const ApiConfig = {
  getProfile: `${url}/user/getProfile`,
  balance: `${url}/bot/getBalance`,
  getOwnerAddress: `${url}/bot/getOwnerAddress`,
  tokenInsight: `${url}/bot/tokenInsight`,
  enquireRepoToken: `${url}/bot/enquireRepoToken`,
  enquireGeneralQuestion: `${url}/bot/enquireGeneralQuestion`,
  tokenTaxDedector: `${url}/bot/getTokensAddresses`,
  getAllTransaction: `${url}/bot/getAllTransaction`,
  fetchMemPool: `${url}/bot/fetchMemPool`,
  getTaxationWalletOfToken: `${url}/bot/findTaxationWalletOfToken`,
  getWalletBalance: `${url}/bot/getWalletBalance`,

  connectWallet: `${url}/user/connectWallet`,
  disconnectWallet: `${url}/user/disconnectWallet`,
  manageAlerts: `${url}/bot/manageAlerts`,
  isSpam: `${url}/bot/isSpam`,
};

export default ApiConfig;
