import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";

const useStyles = makeStyles({
  myComponent: {
    position: "relative",
    background: "#1E2835",
    borderRadius: "20px",
    padding: "35px 0px  ",
    "& h6": {
      color: "#FFF",
      textAlign: "center",
      fontSize: "14px !important",
    },
    "& .buttonBox": {
      position: "absolute",
      left: "20px",
      top: "10px",
      "& button": {
        background: "transparent",
        height: "0px",
        width: "0px",
      },
    },
    "& .walletBoxLink": {
      "& h6": {
        textAlign: "start",
        padding: "0px",
        fontSize: "14px",
      },
    },
  },
});
export default function AskQuestion() {
  const [queryData, setQueryData] = useState("");
  const [queryMessage, setQueryMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [answer, setAnswer] = useState("");
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const sendQuery = async () => {
    try {
      let enquiry = queryMessage.toLowerCase().includes("repo");
      console.log(queryMessage, " ----- enquiry ", enquiry);
      setIsLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: enquiry
          ? ApiConfig.enquireRepoToken
          : ApiConfig.enquireGeneralQuestion,
        bodyData: {
          question: queryMessage,
        },
      });
      if (res.data) {
        setAnswer(res.data.responseResult);
        setIsLoading(false);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Box style={{ position: "relative", height: "94dvh" }}>
      <Box className={classes.myComponent}>
        <Container>
          <Box className="homeCardBox displayStart">
            <IconButton onClick={() => navigate(-1)} className=" walletButton">
              <ArrowBackIcon />
            </IconButton>
            <Box ml={3}>
              <Typography variant="h5" color="#fff">
                Ask Questions
              </Typography>
            </Box>
          </Box>
          {queryData === "" ? (
            <>
              {/* <Box className="homeCardBox displayStart" mt={1}>
                <img src="images/repoToken.png" height={30} alt="" />
                <Box ml={1} onClick={() => setQueryData("REPO")}>
                  <Typography variant="h6">Inquire about REPO Token</Typography>
                </Box>
              </Box> */}
              <Box
                className="homeCardBox displayStart"
                mt={1}
                onClick={() => setQueryData("GENERAL")}
              >
                <img src="images/askQues.svg" height={28} alt="" />
                <Box ml={1}>
                  <Typography variant="h6">Ask a general question</Typography>
                </Box>
              </Box>
            </>
          ) : (
            <Box mt={2}>
              <TextField
                fullWidth
                disabled={isLoading}
                variant="outlined"
                value={queryMessage}
                placeholder="Enter your query"
                onChange={(e) => setQueryMessage(e.target.value)}
              />
              <Box mt={1}>
                <Typography style={{ textAlign: "left" }} variant="h6">
                  {answer && answer}
                </Typography>
              </Box>
              <Box mt={1}>
                {answer ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    fullWidth
                    onClick={() => {
                      setQueryMessage("");
                      setAnswer("");
                    }}
                  >
                    {isLoading
                      ? "Wait..."
                      : answer
                      ? "Ask Another Question"
                      : "Submit"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    fullWidth
                    onClick={() => sendQuery()}
                  >
                    {isLoading ? "Wait..." : "Submit"}
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Container>
      </Box>
    </Box>
  );
}
