import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  IconButton,
  FormHelperText,
  TextField,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import styled from "@emotion/styled";
import { walletAddressRegex } from "src/utils";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ReadProfileBox = styled("Box")(({ theme }) => ({
  "& .closeIcon": {
    position: "absolute",
    top: "0px",
    right: "0px",
  },
  "& .MuiIconButton-root": {
    background: "transparent",
  },

  "& .confirmationDialogBox": {
    "& .titleBox": {
      "& h4": {
        color: "#fff",
        texAlign: "center",
        margin: "16px 0px",
      },
    },

    "& h5": {
      color: "#fff",
      texAlign: "center",
    },
    "& p": {
      color: "rgba(0, 0, 0, 0.60)",
      textAlign: "center",
      width: "100%",
      maxWidth: "320px",
      margin: "16px 0px",
    },
  },
  "& .disclaimerBox": {
    background: "rgba(0, 0, 0, 0.08)",
    borderRadius: "10px",
    padding: "10px",
    "& p": {
      color: "rgba(0, 0, 0, 0.60)",
    },
  },
  buttonNewCss: {
    borderRadius: "50px !important",
    color: "#fff !important",
    padding: "10px 30px !important",
    height: "43px !important",
    background: "rgb(237 27 35) !important",
    border: "1px solid rgb(237 27 35) !important",
  },
}));
export default function CommonConfirmationDialog({
  img,
  desc,
  isLoading,
  open,
  handleClose,
  handleSubmit,
  heading,
  type,
  title,
  replyMessage,
  setMessage,
}) {
  console.log("openopen", open, title);
  const [walletAddress, setWalletAddress] = useState("");
  
  const [isLoadings, setIsLoading] = useState(false);
  const location = useLocation();

  const connectWalletHandler = async () => {
    // if (walletAddress !== "" && validation) {
      try {
        let bodyValue;
        if (title === "Disable Notification") {
          bodyValue = {
            tokenAddress: walletAddress,
            isAlertsOn: "false",
            chatId: location && location.state && location.state.chatID,
            // chatId: "764056787",
          };
        } else {
          bodyValue = {
            tokenAddress: walletAddress,
            isAlertsOn: "true",
            chatId: location && location.state && location.state.chatID,
            // chatId: "764056787",

          };
        }
        setIsLoading(true);
        const res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.manageAlerts,
          bodyData: bodyValue,
        });
        if (res.data.responseCode === 200) {
          // toast.success(res.data.responseMessage);
          toast.success(type==="START"? "Tax Notification is Turned on Successfully":"Tax Notification is Turned off Successfully");
          setIsLoading(false);
          handleClose();
          // navigate("/success", {
          //   state: { data: res.data.result, chatIdURL: location.chatIdURL },
          // });
        } else {
          setIsLoading(false);
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        setIsLoading(false);
      }
    // } else {
    //   setIsSubmit(true);
    // }
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isLoading) {
          handleClose();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <ReadProfileBox>
        <DialogContent style={{ position: "relative" }}>
          <Box style={{ position: "absolute", top: "0px", right: "0px" }}>
            <IconButton onClick={handleClose}>
              <AiOutlineClose />
            </IconButton>
          </Box>
          <Box className="confirmationDialogBox displayColumn">
            <Box className="titleBox">
              <Typography variant="h4">{title}</Typography>
            </Box>
            <Typography variant="h5">{heading}</Typography>
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              disabled={isLoadings}
              variant="outlined"
              placeholder="Enter Token Contract Address"
              onChange={(e) => {
                // if (
                //   walletAddressRegex &&
                //   walletAddressRegex.test(e.target.value)
                // ) {
                //   setValidation(true);
                // } else {
                //   setValidation(false);
                // }
                setWalletAddress(e.target.value);
              }}
            />
            {/* <FormHelperText error>
              {isSubmit &&
                walletAddress === "" &&
                "Please enter your wallet address"}
              {!validation && walletAddress !== "" && "Invalid wallet address"}
            </FormHelperText> */}
          </Box>
          <Box my={3} className="displaySpacebetween">
            {title === "Enable Notification" && (
              <Button
                className="buttonNewCss"
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={() => {
                  connectWalletHandler();
                }}
                fullWidth
              >
                YES
              </Button>
            )}
            {title === "Disable Notification" && (
              <Button
                className="buttonNewCss"
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={() => {
                  connectWalletHandler();
                }}
                fullWidth
              >
                YES
              </Button>
            )}

            <Button
              className="buttonNewCss"
              disabled={isLoading}
              variant="contained"
              color="secondary"
              onClick={handleClose}
              fullWidth
              style={{ marginLeft: "16px" }}
            >
              NO
            </Button>
          </Box>
        </DialogContent>
      </ReadProfileBox>
    </Dialog>
  );
}
