import { Box } from "@mui/material";
import React from "react";
import ClipLoader from "react-spinners/DotLoader";
export default function PageLoading() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <ClipLoader
        color="#36d7b7"
        loading={true}
        // cssOverride={override}
        size={60}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
