// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
// import * as serviceWorker from "./serviceWorker";
// import "../src/scss/main.css";

// ReactDOM.render(
//   <App />,

//   document.getElementById("root")
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
import React from "react";
import ReactDOM from "react-dom/client";
import "src/scss/main.css";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import { ToastContainer, toast } from "react-toastify";
import { createCustomTheme } from "src/theme/index";
import "react-toastify/dist/ReactToastify.css";
const theme = createCustomTheme();
console.log("theme=-=-=-=", theme);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <ToastContainer />
    <App />
  </ThemeProvider>
);
