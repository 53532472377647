import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  IconButton,
  Button,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BackHand, ContentCopy } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import { useAccount, useConnect, useEnsName } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { sortAddress } from "src/utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import WalletAddress from "./WalletAddress";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
const useStyles = makeStyles({
  myComponent: {
    position: "absolute",
    right: "0px",
    width: "100%",
    background: "#1E2835",
    borderRadius: "10px",
    "& h6": {
      color: "#FFF",
      textAlign: "center",
      padding: "10px 0px",
      fontSize: "16px",
      fontWeight: "400",
    },
    "& .buttonBox": {
      position: "absolute",
      left: "20px",
      // top: "10px",
      "& button": {
        background: "transparent",
        height: "0px",
        width: "0px",
      },
    },
    "& .walletBox": {
      background: "#233042",
      borderRadius: "10px",
      border: "1px solid #C9801F",
      padding: "10px",
      margin: "20px",
      flexDirection: "column",
      "& span": {
        color: "rgba(255, 255, 255, 0.60)",
        fontSize: "12px",
        marginTop: "2px",
      },
      "& h6": {
        padding: "0px",
        marginTop: "1px !important",
      },
    },
    "& .walletBoxLink": {
      background: "#233042",
      borderRadius: "10px",
      padding: "10px",
      margin: "20px",
      "& h6": {
        textAlign: "start",
        padding: "0px",
        marginBottom: "7px",
      },
      "& span": {
        color: "#69BFFE",
        fontSize: "14px",
        // marginTop: "20px",
      },
    },
  },
});
export default function ConnectWalletLink() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const disConnectHandler = async () => {
    try {
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.disconnectWallet,
        bodyData: {
          chatId: location && location.state.chatID,
        },
      });
      if (res.data.responseCode === 200) {
        navigate("/", { state: location.state.chatIdURL });
        toast.success(res.data.responseMessage);
      }
    } catch (error) {}
  };

  console.log("=-=-=--=-=location", location);
  return (
    <Box style={{ position: "relative", height: "auto" }}>
      <Container>
        <Box className={classes.myComponent}>
          <Box
            style={{
              position: "relative",
            }}
          >
            <Box className="buttonBox">
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Typography variant="h6">Disconnect Wallet</Typography>
          </Box>
          {location &&
          location.state &&
          location.state.profileData &&
          location.state.profileData.walletAddress ? (
            <Box className="walletBox " display="flex">
              <Button
                variant="contained"
                color="secondary"
                width="100%"
                onClick={() => disConnectHandler()}
              >
                Disconnect{" "}
                {location?.state?.profileData?.walletAddress &&
                  sortAddress(location?.state?.profileData?.walletAddress)}
              </Button>
            </Box>
          ) : (
            <>
              <Box className="walletBox displayCenter">
                <img
                  src="images/walletImg.png"
                  alt=""
                  style={{ height: "50px", width: "50px" }}
                />
                <Typography variant="h6">Connect Wallet</Typography>
                <span>Please connect a wallet holding $REPO Tokens</span>
                <img
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  src="images/button.svg"
                  alt=""
                />
              </Box>
              <Box className="walletBoxLink">
                <WalletAddress
                  location={location && location.state && location.state}
                />
                {/* <ConnectButton /> */}
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
}
