import React, { useEffect, useState } from "react";
import { Box, Grid, Container, Typography, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BackHand } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export default function CommonCard({}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);
  const title = location.state.title;
  useEffect(() => {
    const dataList = location.state.data;

    if (dataList) {
      setListData(dataList);
    }
  }, [location]);

  return (
    <Box style={{ position: "relative", height: "94dvh" }}>
      <Container>
        <Box
          style={{
            position: "absolute",

            right: "0px",
            width: "100%",
            background: "#1E2835",
            borderRadius: "10px",
          }}
        >
          <Box
            style={{
              position: "relative",
              height: "80px",
            }}
          >
            <Box style={{ position: "absolute", left: "20px", top: "10px" }}>
              <IconButton
                style={{
                  background: "transparent",
                  height: "0px",
                  width: "0px",
                }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Typography
              variant="h6"
              style={{
                color: "#FFF",
                textAlign: "center",
                padding: "10px 0px",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              {title}
            </Typography>
          </Box>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            {listData &&
              listData.map((item, i) => {
                return (
                  <Grid item xs={12} key={`card${i}`}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "10px",
                        background: "#222E3F",
                        padding: "15px 8px",
                        margin: "0px 10px",
                      }}
                    >
                      <img src={item.image} />
                      <Typography
                        variant="h6"
                        style={{ color: "#FFF", paddingLeft: "5px" }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
