import { walletAddressRegex } from "src/utils";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
import ButtonCircular from "./ButtonCircular";
import { toast } from "react-toastify";

export default function WalletAddress({ location }) {
  console.log('lofjhha=>>>.',location);
  const navigate = useNavigate();
  const [walletAddress, setWalletAddress] = useState("");
  const [validation, setValidation] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const connectWalletHandler = async () => {
    if (walletAddress !== "" && validation) {
      try {
        setIsLoading(true);
        const res = await apiRouterCall({
          method: "POST",
          url: ApiConfig.connectWallet,
          bodyData: {
            walletAddress: walletAddress,
            chatId: location && location?.chatID,
          },
        });
        if (res.data.responseCode === 200) {
          toast.success(res.data.responseMessage);
          setIsLoading(false);
          navigate("/success", {
            state: { data: res.data.result, chatIdURL: location.chatIdURL },
          });
        } else {
          setIsLoading(false);
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };

  return (
    <>
      <Typography style={{ color: "#FFF" }} variant="h6">
        Wallet Address:
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        disabled={isLoading}
        placeholder="Enter your wallet address"
        onChange={(e) => {
          if (walletAddressRegex && walletAddressRegex.test(e.target.value)) {
            setValidation(true);
          } else {
            setValidation(false);
          }
          setWalletAddress(e.target.value);
        }}
      />
      <FormHelperText error>
        {isSubmit && walletAddress === "" && "Please enter your wallet address"}
        {/* {!validation && walletAddress !== "" && "Invalid wallet address"} */}
      </FormHelperText>
      <Box mt={3} mb={1} style={{ gap: "5px", display: "flex" }}>
        <Button
          color="primary"
          variant="contained"
          disabled={isLoading}
          fullWidth
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="contained"
          fullWidth
          disabled={isLoading}
          onClick={() => connectWalletHandler()}
        >
          {isLoading ? "Connecting..." : "Connect wallet"}{" "}
          {isLoading && <ButtonCircular />}
        </Button>
      </Box>
    </>
  );
}
