import React, { useState, useEffect } from "react";
import { Box, Container, Typography, IconButton, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import { apiRouterCall } from "src/config/service";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import WalletAddress from "./WalletAddress";
import CommonConfirmationDialog from "./CommonConfirmationDialog";

const useStyles = makeStyles({
  myComponent: {
    position: "absolute",
    right: "0px",
    width: "100%",
    background: "#1E2835",
    borderRadius: "10px",
    "& h6": {
      color: "#FFF",

      padding: "10px 0px",
      fontSize: "16px",
      fontWeight: "400",
    },
    "& .buttonBox": {
      position: "absolute",
      left: "20px",
      // top: "10px",
      "& button": {
        background: "transparent",
        height: "0px",
        width: "0px",
      },
    },
    "& .walletBox": {
      borderRadius: "10px",
      border: "1px solid #374D69",
      padding: "10px",
      margin: "5px 20px 20px",
      flexDirection: "column",
      "& h6": {
        padding: "0px",
        marginTop: "1px !important",
        fontSize: " 14px !important",
      },
    },
   

  },
  noticeButton: {
    background: "rgb(237 27 35) !important",
    border: "1px solid rgb(237 27 35) !important",
  },
  buttonNewCss:{
    borderRadius: "50px !important",
  color: "#fff !important",
  padding: "10px 30px !important",
  height: "43px !important"
  }
});
export default function NotificationComponent() {
  const classes = useStyles();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [openEnableTransactionModal, setOpenEnableTransactionModal] =
    useState(false);
  const [openDisableTransactionModal, setOpenDisableTransactionModal] =
    useState(false);

  const navigate = useNavigate();
console.log(">>>>>>location",location);
  const startStopNotification = async (type) => {
    try {
      setIsLoading(true);
      const res = await apiRouterCall({
        method: "POST",
        url: ApiConfig.fetchMemPool,
        bodyData: {
          chatId: location && location.state && location.state.chatID,
          walletAddress:
            location &&
            location.state &&
            location.state.profileData &&
            location.state.profileData.walletAddress,
          poolStatus: type,
        },
      });

      if (res.data) {
        setIsLoading(false);
        toast.success(type==="START"? "Tax Notification is Turned on Successfully":res.data.responseMessage);
        setOpenEnableTransactionModal(false);
        setOpenDisableTransactionModal(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
 


  return (
    <Box style={{ position: "relative", height: "94dvh" }}>
      <Container>
        <Box className={classes.myComponent}>
          <Box
            style={{
              position: "relative",
            }}
          >
            <Box className="buttonBox">
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Typography style={{ textAlign: "center" }} variant="h6">
              Notifications
            </Typography>
          </Box>

          <Box className="walletBox displayCenter">
            <img src="images/bellNotification.png" alt="" />
            <Typography style={{ textAlign: "center" }} variant="h6">
              Notify me each time a transaction occurs from any tax wallet(s)
              associated with a token.
            </Typography>
            <Box>
              <Button
                className={classes.buttonNewCss}
                color="secondary"
                variant="contained"
                disabled={isLoading}
                style={{ margin: "15px 0px 10px", background: "rgb(237 27 35) !important",
                border: "1px solid rgb(237 27 35) !important", }}
                // onClick={() => startStopNotification("START")}
                onClick={() => setOpenEnableTransactionModal(true)}
              >
                Enable
              </Button>
              &nbsp;
              <Button
                className={classes.buttonNewCss}
                color="secondary"
                disabled={isLoading}
                variant="contained"
                style={{ margin: "15px 0px 10px", background: "rgb(237 27 35) !important",
                border: "1px solid rgb(237 27 35) !important", }}
                // onClick={() => startStopNotification("STOP")}
                onClick={() => setOpenDisableTransactionModal(true)}
              >
                Disable
              </Button>
            </Box>
          </Box>
        </Box>
        {openEnableTransactionModal && (
          <CommonConfirmationDialog
            open={openEnableTransactionModal}
            isLoading={isLoading}
            handleClose={() => {
              setOpenEnableTransactionModal(false);
            }}
            title={"Enable Notification"}
            type="START"
            heading={
              "Are you sure you want to proceed with enabling tax alert notifications?"
            }
            handleSubmit={(item) => startStopNotification("START")}
          />
        )}
        {openDisableTransactionModal && (
          <CommonConfirmationDialog
            open={openDisableTransactionModal}
            isLoading={isLoading}
            handleClose={() => {
              setOpenDisableTransactionModal(false);
            }}
            type="STOP"
            title={"Disable Notification"}
            heading={
              "Are you sure you want to proceed with disabling tax alert notifications?"
            }
            handleSubmit={(item) => startStopNotification("STOP")}
          />
        )}
      </Container>
    </Box>
  );
}
